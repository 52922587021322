.checkout {
  width: 100%;
  position: relative;

  .card, .card2 {
    width: 100%;
    max-width: 800px; 
    margin-bottom: 1rem;
    box-sizing: border-box; 
    padding: 1rem;

  }

  .card {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    h3 {
      font-weight: 500;
      font-size: 24px;
    }
  }
  
  .dropdownContainer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    // height: 20px;
  }

  .provinceDropdown {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #777; 
    border-radius: 3px;
    background-color: #fff;
    color: #000000;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .card2 {
    width: 100%;
    max-width: 500px;
    // padding: 1rem;
  }

  form {
    width: 100%;
    display: flex;
    color: #760700;
    // flex-direction: row;
    gap: 2rem;

    div {
      width: 100%;
    }

    label {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      // color: #760700;
    }
    input[type="text"],
    .select,
    .card-details {
      display: block;
      font-size: 1.6rem;
      font-weight: 300;
      padding: 1rem;
      margin: 1rem auto; //auto
      width: 100%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .checkout {
    form {
      flex-direction: column;
      div {
        width: 100%;
      }
    }
  }
}