.table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;

    .continue{
      margin: 2em 0;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border-top: 2px solid #760700;
        border-bottom: 2px solid #760700;
      }
  
      th {
        border: 1px solid #eee;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        
        
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: 1px solid #ccc;
        color: #760700;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
    }
    .summary {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: start;
  
      .card {
        padding: 1rem;
        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            color: #760700;
          }
        }
        button {
          margin-top: 5px;
          // color: #ffca00;
        }
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    button {
      border: 1px solid #7608004a;
    }
    & > * {
      margin-right: 1rem;
    }
  }
  @media screen and (max-width: 600px) {
    /* Adjust table appearance for smaller screens */
    .table table {
      font-size: 1.2rem;
    }
    .count button {
      font-size: 1.2em;
      padding: 5px 8px;
    }
    
  }