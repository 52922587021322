.grid {
    width: 24rem;
    background-color: #fff;
    margin: 5px;
    height: 32rem;
    position: relative;
    border-radius: 15px;
    box-shadow: 0px 5px 6px 3px #c1c1c1 ;
    .img {
      padding: 1rem;
      width: 100%;
      max-height: 75%;
      overflow: hidden;
      border-bottom: 2px solid #eee;
      img {
        width: 100%;
        max-width: 100%;
        // height: 100%;
        // max-height: 100%;
        cursor: pointer;
      }
    }
    .content {
      text-align: center;
      position: absolute;
      bottom: 0.2rem;
      left: 0;
      width: 100%;
  
      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;
        padding: 1rem;
        h4 {
          text-align: left;
          font-weight: 600;
          font-size: 1.4rem;
          
        }
        p {
          font-weight: 600;
          color: #dc0000;
          font-size: 1.6rem;
        }
      }
      // button {
      //   display: block;
      //   width: 100%;
      // }

      // .cartIcon {
      //   position: absolute;
      //   bottom: 30rem; 
      //   right: 1rem; 

      //   // Add a small box background for the cart icon
      //   display: inline-flex;
      //   align-items: center;
      //   justify-content: center;
      //   background-color: #760700; 
      //   width: 4rem; // Adjust the width of the box
      //   height: 3.5rem; // Adjust the height of the box

      //   &:hover {
      //     background-color: rgba(206, 52, 0, 0.844)
      //     // #fdcd0b 
      //   }
      // }

      // button cart icon
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #fff; 
        font-size: 1.5rem; 
      }
    }
  }
  
  .list {
    width: 100%;
    height: 28rem;
    max-height: 32rem;
    display: flex;
    background-color: #fff;
    margin: 1rem 0;
    .img {

      width: 100%;
      max-width: 20rem;
      height: 100%;
      overflow: hidden;
      border-right: 2px solid #eee;
      
      img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  
    .content {
      position: relative;
      padding: 1rem;
      width: 65%;
      .details {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        h4 {
          font-size: 2.8rem;
          font-weight: 600;
        }
        
        p {
          font-weight: 600;
          color: var(--color-danger);
          font-size: 1.8rem;
          color: #dc0000;
        }
        
      }
   
      // .cartButton {
      //   /* "Add to cart" button in list view */
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   background-color: orangered;
      //   width: 100%; /* width of the button */
      //   height: 3.5rem; /* height of the button */
      //   border: none;
      //   border-radius: 5px;
      //   color: #fff;
      //   font-size: 1.5rem;
      //   cursor: pointer;
      //   margin-top: 1rem; /* space between items in list view */
    
      //   &:hover {
      //     background-color: rgba(135, 55, 29, 0.844);
      //   }
      // }
    }
   
  }
  @media screen and (max-width: 768px) {
    
    .details {
      h4 {
        text-align: left !important;
        font-weight: 600 !important;
        font-size: 1.6rem !important;
        
      }
      p {
        font-weight: 600 !important;
        color: #dc0000 !important;
        font-size: 1.4rem !important; 
      }
    }
    
  }
  