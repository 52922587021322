.table {
  padding: 5px;
  width: 100%;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;

    thead {
      border-top: 2px solid #760700;
      border-bottom: 2px solid #760700;
    }

    th {
      border: 1px solid #eee;
    }

    th,
    td {
      vertical-align: top;
      text-align: left;
      padding: 8px;
      &.icons {
        > * {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }

    tr {
      border-bottom: 1px solid #ccc;
      color: #000000;
    }

    tr:nth-child(even) {
      background-color: #eee;
    }

    .actionButtons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
  
      a,
      button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        font-size: 1.2rem;
        text-decoration: none;
        transition: background-color 0.3s;
        color: #fff;
        width: 150px;

        // extra
        &:hover {
          filter: brightness(90%);
        }
      }
  
      // Review Product button
      .reviewButton {
        background-color: #ffca00;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.38);

      }
  
      // Return Product button
      .returnButton {
        background-color: #dc3545;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.38);

      }
  
      // Track Order button
      .trackButton {
        background-color: #28a745;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.38);

      }
    }
  }
  .summary {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: start;

    .card {
      padding: 1rem;
      .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          color: var(--color-danger);
        }
      }
      button {
        margin-top: 5px;
      }
    }
  }
}
.count {
  display: flex;
  align-items: center;
  button {
    border: 1px solid var(--darkblue);
  }
  & > * {
    margin-right: 1rem;
  }
}