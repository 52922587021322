.navbar {
    border-right: 1px solid #7608003e;
    min-height: 80vh;
  
    .user {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 4rem;
      background-color: #f6c500;
  
      h4 {
        color: #000000;
      }
    }
  
    nav ul {
      li {
        border-bottom: 1px solid #7608003e;
        padding: 1rem;
        position: relative;
        a {
          display: block;
          width: 100%;
        }
      }
    }
  }
  
  .active {
    cursor: pointer;
  }
  
  .active::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: #760700;
  }