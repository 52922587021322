.table {
    padding: 5px;
    width: 100%;
    overflow-x: auto;
  
    table {
      border-collapse: collapse;
      width: 100%;
      font-size: 1.4rem;
  
      thead {
        border-top: 2px solid #760700;
        border-bottom: 2px solid #760700;
      }
  
      th {
        border: 1px solid #eee;
      }
  
      th,
      td {
        vertical-align: top;
        text-align: left;
        padding: 8px;
        &.icons {
          > * {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
  
      tr {
        border-bottom: 1px solid #ccc;
        color: #000000;
      }
  
      tr:nth-child(even) {
        background-color: #eee;
      }
    }
    .summary {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: start;
  
      .card {
        padding: 1rem;
        .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          h3 {
            color: var(--color-danger);
          }
        }
        button {
          margin-top: 5px;
        }
      }
    }
    .returnBottomDetails {
      display: flex;
    flex-direction: row; /* Display the components in a row */
    justify-content: flex-start; /* Add space between the components */
    gap: 1em;
    /* Additional styles for the container div wrapping ReturnMessage and ChangeReturnStatus components */
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
    }
    @media screen and (max-width: 700px) {
      .returnBottomDetails{
        gap: 0em;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .count {
    display: flex;
    align-items: center;
    button {
      border: 1px solid var(--darkblue);
    }
    & > * {
      margin-right: 1rem;
    }
  }
  