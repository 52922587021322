section{
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  /* Your container styles here */
  /* ... */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
}
.wishlist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    .card {
      
      width: 100%;
      max-width: 500px;
      padding: 1rem;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #f9f9f9;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease;
    
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a slight elevation on hover */
        }
    
        div {
          margin-bottom: 0.5rem; /* Add some space between each line in the card */
        }
    
        p {
          font-size: 1.6rem;
          font-weight: 300;
    
          b {
            font-weight: 500;
          }
        }
        .uploadContainer {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 1rem; /* Add margin between the container and the button */
          
          
         
        }
        .bottomIconWishlist{
          position: relative;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          
        }
        
        .uploadImageContainer{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          
          .uploadedImageRow {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            gap: 1rem;
            width:100% ;
            overflow-x: auto;
            overflow-y: hidden;
          }

          .uploadedImageContainer {
            position: relative;
            img {
              /* Adjust the size of the images here */
              width: 200px; /* Set the width of the images */
              height: 150px; /* Set the height of the images */
              object-fit: cover; /* Resize the image to cover the entire container while preserving aspect ratio */
             
              
            }
          }
        
          .deleteImageButton {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            background-color: red;
            border-radius: 50%;
            color: white;
            font-weight: bold;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
            outline: none;
          }
        }
        

        .uploadButton {
        display: inline-block;
          cursor: pointer;
          
          
        }
        .uploadedText {
            font-size: 1.6rem;
            font-weight: 300;
            margin-right: 0.5rem; /* Add spacing between the text and the button */
        }
        .imageIcon {
          margin-top: 0.2rem;
          font-size: 3rem;
        }
        .profileInfo {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
        .iconTextContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 0.5rem;
          position: relative;
        }
        .iconTextContainer::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -5px; /* Adjust the distance of the line from the status text */
          width: 100%;
          height: 1px;
          background-color: #ccc;
        }

        .wishlist-user-name{
          font-weight: 700;
          font-size: 18px;
        }
        .wishlist-date{
          font-weight: 400;
          font-size: 12px;
        }
        .wishlist-status{
          margin-left: 1rem;
          margin-bottom: 1rem;
          font-weight: 500;
        }
        
        .fileInput {
          display: none;
        }
        .wishlistImage {
        
          display: grid;
          align-items: center;
          gap: 10px;
        
          div {
            width: 100%;
            padding-top: 66.66%; /* Set the aspect ratio of 3:2 (for example) */
            position: relative;
            overflow: hidden;
          }
        
          img {
            /* Adjust the size of the images here */
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; /* Resize the image to cover the entire container while preserving aspect ratio */
            display: block;
            
          }
          &.twoRows {
            grid-template-columns: repeat(2, 1fr);
            
            div {
              padding-top: 100%;
            }
            
            
            
          }
          &.threeRows {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .wishlistLabel{
          width: 100%;
        }
        .centeredImage {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 450px; /* Set a fixed height for the single image container */
          overflow: hidden; /* Hide any overflowing content (image) within the div */
          text-align: center;
        }
        .centered-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          
        }
        
        .centeredImage img {
          width: auto;
          height: 100%;
          object-fit: cover;
          object-position: center; /* Center the image within the container */
        }
        
      }
      textarea {
        resize: none; 
      }
      
    form {
      label {
        display: block;
        font-size: 1.6rem;
        font-weight: 700;
        color: #760700;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
        color: #000000;
      }
      
      h5 {
        color: #000000;
      }
    }
  }
.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #760700;
  color: #ffffff;
  padding: 10px;
  border: none;
  z-index: 999;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #ffca00;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .icon {
    font-size: 24px;
  }
}
  
