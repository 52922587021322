.auth {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgSignUp{
      animation: slide-down 0.5s ease;
      img {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
    .imgLogin {
      animation: slide-down 0.5s ease;
      
      img {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
    .imgReset{
      animation: slide-down 0.5s ease;
      img {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
    .reset{
      width: 35rem;
      padding: 1rem;
      height: 38rem;
      
      animation: slide-up 0.5s ease;
      .resetWrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      h2 {
        color: #dc0000;
        margin-top: 5rem ;
        text-align: center;
      }
      form {
        input[type="text"],
        input[type="email"]
  
        {
          display: block;
          font-size: 1.6rem;
          font-weight: 300;
          padding: 1rem;
          margin: 2rem auto;
          width: 100%;
          background-color: #f6f7f9;
          border: 1px solid #f6f7f9;
          border-radius: 15px;
          outline: none;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
        .input-box input {
          padding-left: 50px; 
        }
        .links {
          
          display: flex;
          justify-content: space-between;
          margin-top: 3.5rem;
        }
        .resetIcon{
          margin: 0 0.5em;
          position: relative;
          top: 5px;
          font-size: 1.4em;
        }
        a {
          
          color: #dc0000;
          text-decoration: none;

      
          &:hover {
            text-decoration: underline;
          }
        }
        
        p {
          text-align: center;
          margin: 1rem;
          font-weight: 500;
        }
      }
      .register {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
        gap: 0.5em;
        a {
         
          color: #dc0000;
          text-decoration: none;
  
         
          &:hover {
            text-decoration: underline;
          }
        }
      }

    }

    .input-background {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 40px; 
      height: 40px; 
      background-color: white; 
      border-radius: 15px; 
      border: 1px solid red;
    }
    
   
    .input-box {
      position: relative;
      
    }
    .input-icon {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: #dc0000;
    }
  
    .form {
      width: 35rem;
      max-width: 100%;
      padding: 1.5rem;
      animation: slide-up 0.5s ease;
      h2 {
        color: #dc0000;
        margin-bottom: 2.5rem;
        text-align: center;
      }
      form {
        input[type="text"],
        input[type="email"],
        input[type="password"]
        {
          display: block;
          font-size: 1.6rem;
          font-weight: 300;
          padding: 1rem;
          margin: 1rem auto;
          width: 100%;
          background-color: #f6f7f9;
          border: 1px solid #f6f7f9;
          border-radius: 15px;
          outline: none;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
        .input-box input {
          padding-left: 50px; 
        }
        .links {
          
          display: flex;
          justify-content: space-between;
          margin: 5px 0;
        }
      
        a {

          color: #dc0000;
          text-decoration: none;

      
          &:hover {
            text-decoration: underline;
          }
        }
        
        p {
          text-align: center;
          margin: 1rem;
          font-weight: 500;
        }
      }
      .googleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      
        button {
          display: flex;
          align-items: center;
          gap: 10px; 
          cursor: pointer;
        }
      
        .googleIcon {
          font-size: 1.5em;
        }
      }
      .register {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        gap: 0.5em;
        a {
         
          color: #dc0000;
          text-decoration: none;
  
         
          &:hover {
            text-decoration: underline;
          }
        }
      }
      
      .password-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
      }
      
      .eyePassword{
        position: absolute;
        right: 10px; 
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.5em;
        color: #dc0000;
        z-index: 1;
        
      }
      .icon-span{
        cursor: pointer;
      }
      .tracker-box {
        margin-top: 2em;
        opacity: 0;
        pointer-events: none;
        transform: translateY(20px);
        transition: 0.5s ease;
        color: #979797;
      
        &.show {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .password-box {
        input:focus + .tracker-box {
          @extend .show;
        }
      
        input:not(:focus) + .tracker-box.hide {
          display: none;
        }
      }
      .tracker-box div{
        margin: 5px 0;
        font-size: 1.3em;
      }
      .tracker-box .validated{
        color: #008800;
      }
      .list-icon{
        margin-right: 8px;
      }
      .list-icon .green{
        color: #008800;
      }
     
    }
    


    @keyframes slide-up {
      0% {
        transform: translateY(-5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
    @keyframes slide-down {
      0% {
        transform: translateY(5rem);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .imgSignUp, .imgLogin , .imgReset {
      display: none;
    }

  }