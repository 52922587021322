.pagination {
    list-style: none;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 2px solid #ccc;
  
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    
    .hidden {
      display: none;
    }
    
    li {
      font-size: 1.4rem;
      font-weight: 500;
      border: 1px solid #ccc;
      min-width: 3rem;
      height: 3rem;
      padding: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // color: #d7a900;
      
    }
    p {
      margin-left: 1rem;
    
      .page {
        color: #dc0000;
      }
    }
  }
  .active {
    background-color: #dc0000;
    color: #fff;
    
    
  }