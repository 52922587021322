.review {
  .continue {
    color: #760700;
    background-color: transparent;
    border-style: none;
    cursor: pointer;
    font-size: 1.4em;
    text-decoration: none;
    transition: all 0.2s;
  }
  .continue:hover {
   
    font-size: 1.5rem;
  }
  .card_img {
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    margin-bottom: 23px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    .prod_name {
      // left: 0;
      padding-bottom: 10px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
    .card {
      width: 100%;
      max-width: 500px;
      padding: 1rem;
    }
    form {
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
    }
    textarea {
      resize: none;
    }
  }