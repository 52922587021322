form {
  gap: 2rem;
}

.checkout {
    width: 100%;
    position: relative;
    margin-top: -9rem;
  
    .card {
      width: 100%;
      max-width: 500px;
      padding: 1rem;
      h3 {
        font-weight: 300;
      }
    }
  
    .checkoutForm {
      width: 100%;
      display: flex;
      color: #760700;
  
      div {
        width: 100%;
      }
  
      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      .select,
      .card-details {
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
      }
    }
  }
  
  @media screen and (max-width: 700px) {
    .checkout {
      .checkoutForm {
        flex-direction: column;
        div {
          width: 100%;
        }
      }
    }
  }
  
  // Payment Element styles
  
  #payment-message {
    color: rgb(105, 115, 134);
    color: red;
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  .button {
    background: #f6c500; // checkout summary
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    position: relative;
  }
  
  .button:hover {
    filter: contrast(115%);
  }
  
  .button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  @media only screen and (max-width: 600px) {
    .checkoutForm {
      width: 80vw;
      min-width: initial;
    }
  }