.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  background-color: var(--color-dark);
  

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
   

    &.current {
      opacity: 1;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      
    }
  }
  
  .slide-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 15px;
    width: 100%;
    z-index: 1;

    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 5px;
      background-color: #ffffff6f;
      cursor: pointer;
      border: 1px solid #77777759;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.6);
    }

    .active {
      background-color: #fff;
    }
  }

}

  
@media screen and (max-width: 600px) {
  .slider {
    height: 30vh;
  }
}
 
  
  .content {
    position: absolute;
    text-align: center;
    top: 23rem;
    left: 50%;
    opacity: 0;
    width: 50%;
    padding: 3rem;
    display: flex;
    justify-self: center;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.4);
    animation: slide-up 1s ease 0.5s;
    animation-fill-mode: forwards;
    transition: opacity 0.5s ease, top 1s ease-in-out;
    //   visibility: hidden;
    h2 {
      font-size: 4.5rem;
    }
  }
  
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 23rem;
    }
    100% {
      visibility: visible;
      top: 17rem;
    }
  }
  
  @media screen and (max-width: 600px) {
    .content {
      width: 80%;
    }
  }
  
  .content > * {
    color: #fff;
    margin-bottom: 1rem;
  }
  
  .current {
    opacity: 1;
    transform: translateX(0);
  }
  
  .current .content {
    opacity: 1;
  }
  .arrow {
    border: none;
    border-radius: 50%;
    background: transparent;
    color: #fff;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .arrow:hover {
    background: #ffffffa5;
    color: rgb(176, 0, 0);
  }
  
  .next {
    right: 1.5rem;
    color: orangered;
  }
  .prev {
    left: 1.5rem;
    color: orangered;
  }
  
  hr {
    height: 2px;
    background: #fff;
    width: 50%;
  }