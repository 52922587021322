.fixed {
    width: 100%;
    position: sticky;
    top: 0;
    transition: all 0.5s;
    z-index: 1000;
    right: 0;
  }

.toast-message{
  font-size: 12px;
  font-weight: 500;
  border: 2px solid #a01212;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.header-fixed-cart p {
  margin-left: 8px;
  }
  
  header {
    width: 100%;
    background-color: var(--dark-blue);
    color: #fff;
  
    .header {
      width: 100%;
      height: 8rem;
      max-width: 1000px;
      margin: 0 auto;
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
    .logo img {
      width: 100%;
      height: auto;
      object-fit: contain;
      cursor: pointer;
      max-height: 120px;
      
    }
    
  
    nav {
      width: 160%;
      display: flex;
      justify-content: space-between;

      .adminbtn_sidebar{
        display: none;
      }

      ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
  
        .logo-mobile {
          display: none;
          
        }
        
        // To adjust Home, Contact us, Wishlist, and admin space bet
        li {
          margin: 0 5px;
          a {
            color: #fff;
            &:hover {
              color: #fdca0f;
            }
          }
        }
      }

      .displayNameWrapperMediaScreen {
        .user_sidebar {
          display: none;
        } // Hides it by default
      }

    }
  
    .header-right {
      display: flex;
  
      .cart a {
        display: flex;
        color: #fff;
        position: relative;
        &:hover {
          color: #fdca0f;
        }
        &.active {
          color: #fdca0f;
        }
        p {
          position: absolute;
          top: -1rem;
          right: -1rem;
          font-weight: 500;
        }
      }
  
      span {
        margin: 0 5px;
  
        p {
          color: #fff;
        }
      }
  
      .links a {
        margin: 0 5px;
        color: #fff;
        &:hover {
          color: #fdca0f;
        }
        &.active {
          color: #fdca0f;
        }
      }
    }
  
    .menu-icon {
      cursor: pointer;
      display: none;
    }

    @media screen and (min-width: 801px) {
      nav {
        .adminbtn_sidebar {
          display: none; /* Hide the admin button in normal screens */
        }
      }
    }
    
    @media screen and (max-width: 800px) {
    
      nav {
        .adminbtn_sidebar {
          display: block;
          position: absolute;
          top: 15px;
          right: 30px;
          z-index: 11;
        }
      }
  
    }


    @media screen and (max-width: 800px) {
      .logo img {
        max-height: 60px; 
      }

      .show-nav {
        transform: translateX(0);
    
        .displayNameWrapperMediaScreen {
          
          .user_sidebar {
            position: absolute;
            padding-bottom: 10px;
            padding-top: 10px;
            padding-left: 20px;
            padding-right: 20px;
            bottom: 0px;
            left: 0px;
            width: 100%;
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            border-top: 1px solid #a01212;
            z-index: 1;
          }
          
        }
        
      }
      
      nav {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100vh;
        background-color: var(--dark-blue);
        padding: 1rem;
        z-index: 999;
        transform: translateX(-200%);
        transition: all 0.3s;

        .nav-wrapper {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100vh;
          background-color: rgba(0, 0, 0, 0.5);
          transform: translateX();
          transition: all 0.3s;
        }
  
        .show-nav-wrapper {
          transform: translateX(100%);
        }
  
        ul {
          display: block;
          .logo-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > * {
              cursor: pointer;
            }
          }
          li {
            padding: 5px 0;
            border-bottom: 1px solid #a83737;
            a {
              display: block;
            }
          }
          .adminbtn{
            display: none;
          }
        }
  
        .header-right {
          display: block;
          .cart {
            display: block;
            padding: 5px 0;
            border-bottom: 1px solid #a83737;
            a {
              position: relative;
              p {
                position: absolute;
                top: -1rem;
                left: 5rem;
                font-weight: 500;
              }
            }

          }
  
          .links {
            display: block;
            a {
              display: block;
              margin: 0;
              padding: 5px 0;
              border-bottom: 1px solid #a83737;
              // display: none;
              
            }
            .user {
              display: none;
            }
            
          }
        }
      }
      .cart {
        display: block;
        padding: 5px 0;
        // border-bottom: 1px solid #333;
        a {
          color: #fff;
          position: relative;
          &:hover {
            color: #fdca0f;
          }
          p {
            position: absolute;
            top: -1rem;
            left: 5rem;
            font-weight: 500;
            color: #fff;
          }
        }
      }

      .show-nav {
        transform: translateX(0);
      }
      .hide-nav {
        transform: translateX(-200%);
      }
      .menu-icon {
        display: flex;
        > * {
          margin-left: 2rem;
        }
      }
    }
  }
  
  .active {
    position: relative;
    color: #daa51f;
  }
  
  .active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background-color: #daa51f;
  }