// .custom-toast {
//   font-size: 12px;
// }

.product {
  width: 100%;
  max-width: 1000px;
  margin: 2rem 0;
    .desc {
      padding-top: 2rem;
      & > p {
        margin-bottom: 1rem;
      }
    }
    
    .prod_det {
      padding: 0.5px;
      margin-top: 2rem;
      .detailsProduct {
        justify-content: center;
        align-items: center;
        .content {
          margin-left: 1.8rem;
          // right: 150rem;
          h3 {
            font-size: 25px;
            font-weight: 600;
          }
          .price {
            font-size: 30px;
            font-weight: 600;
          }
          .desc {
            word-wrap: break-word;
            padding-top: 0;
          }
        }
      }
    }

    .card_desc {
      padding: 1rem;
      margin-top: 2rem;
      h3 {
        font-size: 20px;
      }
      
    }

    .card {
      padding: 1rem;
      margin-top: 2rem;

      h3 {
        font-size: 20px;
      }
    }
    .review {
      border-top: 1px solid #ccc;
    }
    .detailsProduct {
      // padding-top: 2rem;
      display: flex;
      // padding-bottom: 2rem;
      padding: 2rem;
  
      .img {
        width: 45%;
        border: 1px solid #ccc;
        border-radius: 3px;
        img {
          width: 100%;
        }
      }
  
      .content {
        width: 55%;
        padding: 0 5px;
        & > * {
          margin-bottom: 1rem;
        }
        .price {
          color: #97180f;
          font-weight: 500;
        }
  
        .count {
          display: flex;
          align-items: center;
          & > * {
            margin-right: 1rem;
          }
        }

        .colorButtons {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
        

          .colorButton {
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 5px 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: transparent;
            cursor: pointer;
            font-size: 1.5rem;
            color: rgba(52, 52, 52, 0.923);


            &.active {
              background-color: #97180f;
              color: #fff;
            } 
          }
        }

        .sizeButtons {
          display: flex;
          flex-wrap: wrap;
          margin-top: 5px;
  
          .sizeButton {
            margin-right: 5px;
            margin-bottom: 5px;
            padding: 5px 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            background-color: transparent;
            cursor: pointer;
            font-size: 1.5rem;
            color: rgba(52, 52, 52, 0.923);

            &.active {
              background-color: #97180f;
              color: #fff;
            }
          }
        }
      }
    }

  
}
@media screen and (max-width: 700px) {
  .product {
    .detailsProduct {
      flex-direction: column;
   
      .img {
        width: 100%;
      }

      .content {
        width: 100%;
      }
    }
  }
}