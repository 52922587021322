.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .modalContent {
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    
    right: 10px;
    color: red;
    font-size: 4em;
    cursor: pointer;
  }
  
  .enlargedImage {
    max-width: 90vw;
    max-height: 90vh;
  }